import React from 'react';
import Container from '@material-ui/core/Container';

import { useStaticQuery, graphql, Link } from 'gatsby';
import Seo from '../components/seo';
import LegalLayout from '../components/LegalLayout';

export const Head = () => <Seo title="Data Protection Addendum" />;

export default function Contact() {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  return (
    <LegalLayout>
      <Container maxWidth="md" component="main" className="dpa">
        <h1>Data Protection Addendum</h1>
        <p>Version 1.0</p>
        <p>Last Updated Date: July 24, 2021</p>
        <p>
          This Data Protection Addendum (the “Addendum”) applies to the
          processing of Personal Data by Plainice, LLC (“Plainice”) including,
          without limitation, Personal Data relating to data subjects located in
          the European Economic Area, Switzerland or the United Kingdom (“EU
          Personal Data”) and consumers located in California (“CA Personal
          Data”). This Addendum supplements the online Terms of Use or other
          signed agreement entered into between you (“Customer”) and Plainice
          for the provision of Plainice’s products and services (the “Services”)
          to Customer (the “Agreement”) and is incorporated into the Agreement.
          In the event of a conflict between this Addendum and any other terms
          in the Agreement, the terms of this Addendum will govern.
        </p>
        <p>
          “Data Controller”, “Data Processor”, “subprocessor“, “Supervisory
          Authority”, “data subject” and “process” have the meanings given in
          the relevant Data Protection Requirements (as defined below).
          “Consumer”, “business”, “sale”, and “service provider” shall have the
          meaning given in the CCPA (as defined below). “Personal Data” means
          (a) the personal data (as defined in GDPR) that Customer provides to
          Plainice for the provision of the Services and (b) any other
          information that Customer provides to Plainice for the provision of
          the Services that constitutes “personal information” under and
          governed by the CCPA.
        </p>
        <p>
          As between the parties, with regard to EU Personal Data, Customer is a
          Data Controller and Plainice may be either a Data Processor for a
          Customer entity located in the EU or a subprocessor with regard to EU
          Personal Data.
        </p>
        <p>
          As between the parties, with regard to CA Personal Data, Customer is a
          business and Plainice is a service provider.
        </p>
        <p>
          Plainice reserves the right to modify this Addendum in order to comply
          with applicable law and regulation. To the extent that Plainice
          modifies this Addendum in order to ensure such compliance, Plainice
          will provide notice to Customer of the modifications, and Customer’s
          continued use of the Services will constitute Customer’s agreement to
          those modifications. Plainice may provide that notice in a variety of
          ways, including, among other things, sending Customer an email,
          posting a notice on the Service itself, or by posting the revised
          Addendum on Plainice’s website and revising the date at the top of
          this Addendum.
        </p>
        <h1>1. Nature of Data Processing</h1>
        <p>
          The subject matter of the data processing, including the processing
          operations carried out by Plainice on behalf of Customer and
          Customer’s data processing instructions for Plainice, will be
          described in the Agreement, this Addendum, and each statement of work,
          order form, or equivalent document where Customer orders Services from
          Plainice, which form integral parts of the Agreement.
        </p>
        <p>
          <strong>Categories of data subjects:</strong> Individuals who may use
          Plainice’s Services as provided to Customer under the Agreement.
        </p>
        <p>
          <strong>Types of Personal Data processed:</strong> Personal Data
          provided by Customer to Plainice in connection with the Agreement,
          including name, surname, email address, other profile information, and
          content of messages sent by data subjects in connection with the
          Services under the Agreement.
        </p>
        <h1>2. Compliance with Laws.</h1>
        <p>
          The parties shall each comply with their respective obligations under
          all applicable laws, regulations, and other legal requirements
          relating to (i) privacy, data security, consumer protection,
          marketing, promotion, and text messaging, email, and other
          communications; and (ii) the use, collection, retention, storage,
          security, disclosure, transfer, disposal, and other processing of any
          Personal Data (“Privacy Laws”), including, without limitation, the
          California Consumer Privacy Act of 2018 (as amended) (“CCPA”). With
          regard to EU Personal Data, the parties will comply with each of their
          respective obligations under the EU Data Protection Directive 95/46/EC
          (as amended), (the “Directive”), any subordinate legislation and
          regulation implementing the Directive which may apply (“Local Data
          Protection Laws”), and, as of 25 May 2018 and thereafter, the European
          Union Regulation on the protection of natural persons with regard to
          the processing of personal data and on the free movement of such data,
          and repealing Directive 95/46/EC (the “General Data Protection
          Regulation” or “GDPR”) and any subordinate legislation and regulation
          implementing the GDPR which may apply (collectively, with Privacy
          Laws, the “Data Protection Requirements”).
        </p>
        <h1>3. Customer Obligations.</h1>
        <p>Customer shall:</p>
        <h3>3.1</h3>
        <p>
          provide instruction to Plainice and determine the purposes and general
          means of Plainice’s processing of Personal Data on behalf of Customer
          under the Agreement; and
        </p>
        <h3>3.2</h3>
        <p>
          comply with its personal data protection, data security and other
          obligations prescribed by Data Protection Requirements for Data
          Controllers by, without limitation, meeting its obligations under Data
          Protection Requirements to:
        </p>
        <ol>
          <li>
            establish and maintain a procedure for the exercise of the rights of
            the individuals whose EU Personal Data Plainice processes on behalf
            of Customer;
          </li>
          <li>
            as required by Data Protection Requirements, provide notice and
            obtain consent from the individuals whose EU Personal Data Plainice
            processes on behalf of Customer;
          </li>
          <li>
            establish or ensure that another party has established a legal basis
            for Plainice’s processing of Personal Data contemplated by this
            Addendum;
          </li>
          <li>
            process only data that have been lawfully and validly collected and
            ensure that such data will be relevant and proportionate to the
            respective uses; and
          </li>
          <li>
            ensure compliance with the provisions of this Addendum by its
            personnel and by any person accessing or using Personal Data on its
            behalf.
          </li>
        </ol>
        <h3>3.3</h3>
        <p>
          By entering into this Addendum, Customer instructs Plainice to process
          Customer Personal Data only in accordance with applicable law: (a) to
          provide the Services; (b) as authorised by the Agreement, including
          this Addendum; and (c) as further documented in any other written
          instructions given by Customer and acknowledged in writing by Plainice
          as constituting instructions for purposes of this Addendum.
        </p>
        <h1>4. Plainice Obligations</h1>
        <h3>4.1</h3>
        <p>
          Plainice, in its capacity as a Data Processor or subprocessor of
          Personal Data, shall:
        </p>
        <ol>
          <li>
            process Personal Data solely for the purposes of providing the
            Services as described in the Agreement (which shall encompass the
            processing authorized by Customer’s instructions), and in compliance
            with the instructions received from Customer and the Agreement;
          </li>
          <li>
            not sell any CA Personal Data or retain, use or disclose CA Personal
            Data outside of the direct business relationship between Plainice
            and Customer;
          </li>
          <li>
            inform Customer immediately if, in Plainice’s opinion, an
            instruction from Customer violates applicable Data Protection
            Requirements;
          </li>
          <li>
            adopt and maintain appropriate security measures including
            organizational and technical measures (the “Security Measures” as
            set forth in Attachment 1), designed to ensure a level of security
            appropriate to the risks presented by processing the Personal Data,
            taking into account the state of the art, the costs of
            implementation and the nature, scope, context and purposes of
            processing as well as the risk of varying likelihood and severity
            for the rights and freedoms of natural persons;
          </li>
          <li>
            grant access to Personal Data only to personnel who need such access
            for the scope of their job duties, and are subject to appropriate
            confidentiality arrangements;
          </li>
          <li>
            if it intends to engage one or more third parties acting on its
            behalf (“subprocessor”) to help it to satisfy its obligations in
            accordance with this Addendum or to delegate all or part of the
            processing activities to such subprocessors, (i) remain responsible,
            and liable, to Customer for the subprocessors’ acts and omissions
            with regard to data protection; and (ii) enter into contractual
            arrangements with such subprocessors requiring them to provide a
            substantially similar level of data protection compliance and
            information security to that provided for herein. Subject to the
            requirements of this Section 4.1(F), Customer hereby generally
            authorizes the engagement of subprocessors. Information about
            subprocessors is available at{' '}
            <Link to="/help/do-you-use-any-subprocessors/">
              {siteUrl}/help/do-you-use-any-subprocessors
            </Link>{' '}
            (as may be updated by Plainice from time to time). When any new
            subprocessor is engaged during the term of the Agreement, Plainice
            will notify Customer of the engagement by updating this website. If
            Customer objects to such engagement in a written notice to Plainice
            within 15 days of being informed thereof on reasonable grounds
            relating to the protection of Personal Data, Plainice and Customer
            will work together in good faith to find a mutually acceptable
            resolution to address such objection. If the parties are unable to
            reach a mutually acceptable resolution within a reasonable
            timeframe, Customer may, as its sole and exclusive remedy, terminate
            the Agreement and cancel the Services by providing written notice to
            Plainice.
          </li>
        </ol>
        <h3>4.2</h3>
        <p>
          Plainice shall inform Customer without delay if Plainice becomes aware
          of:
        </p>
        <ol>
          <li>
            any legally binding request for disclosure of Personal Data by a law
            enforcement authority, unless otherwise prohibited, such as in order
            to preserve the confidentiality of an investigation by the law
            enforcement authorities; or
          </li>
          <li>
            any notice, inquiry or investigation by a Supervisory Authority with
            respect to Personal Data.
          </li>
        </ol>
        <h3>4.3</h3>
        <p>
          Plainice further agrees to notify Customer of any accidental or
          unlawful destruction, loss, alteration, unauthorized disclosure of, or
          access to Personal Data in Plainice’s possession, custody or control
          (“Personal Data Breach”) without undue delay and in any event within
          72 hours of becoming aware of a Personal Data Breach. Personal Data
          Breaches do not include unsuccessful attempts or activities that do
          not compromise the security of Personal Data, including unsuccessful
          log-in attempts, pings, port scans, denial of service attacks, or
          other network attacks on firewalls or networked systems.
        </p>
        <h3>4.4</h3>
        <p>Plainice shall reasonably assist Customer regarding:</p>
        <ol>
          <li>
            any requests from data subjects in respect of access to or the
            rectification, erasure, restriction, portability, blocking or
            deletion of Personal Data. In the event that a data subject sends
            such a request directly to Plainice, Plainice will direct the data
            subject to submit such request to Customer directly, and Customer
            shall be responsible for responding to such requests;
          </li>
          <li>
            the investigation of Personal Data Breaches and the notification to
            the Supervisory Authority and data subjects in respect of such
            breaches by providing available details of the Personal Data
            breaches, including steps Plainice has taken to mitigate the
            potential risks and steps Plainice recommends Customer take to
            address the Information Security Incident; and
          </li>
          <li>
            the preparation of data protection impact assessments and, where
            applicable, carrying out consultations with any Supervisory
            Authority.
          </li>
        </ol>
        <h3>4.5</h3>
        <p>
          If Plainice is required by Data Protection Requirements to process any
          Personal Data other than as set forth in this Addendum, Plainice shall
          inform Customer of this requirement in advance of any processing,
          unless Plainice is legally prohibited from informing Customer of such
          processing.
        </p>
        <h1>5. Audit; Certification.</h1>
        <p>
          Customer may audit Plainice’s compliance with this Addendum up to once
          per year and on such other occasions as may be required by Data
          Protection Requirements. Plainice will cooperate with the audit by
          providing Customer or Customer’s Supervisory Authority with the
          information and assistance reasonably necessary to conduct the audit.
          Customer will reimburse Plainice for its reasonable expenses incurred
          to cooperate with such an audit. For the purposes of this section,
          “Supervisory Authority” has the same meaning as given by Article 28 of
          the Directive or, from 25 May 2018, Article 51 of the General Data
          Protection Regulation. The audit must be conducted during regular
          business hours, subject to an agreed upon audit plan and Plainice’s
          safety, security or other relevant policies, and may not unreasonably
          interfere with Plainice’s business activities. Plainice shall not be
          required to breach any duties of confidentiality in connection with
          such audit, and Customer may use the audit reports only for the
          purposes of meeting Customer’s regulatory audit requirements and/or
          confirming compliance with the requirements of this Addendum.
        </p>
        <h1>6. Data Transfers.</h1>
        <p>
          Plainice is located in the United States and may store and process
          Personal Data in the United States or anywhere Plainice or its
          Subprocessors maintains facilities. To the extent Slack maintains the
          Customer’s Slack workspace in the EEA, Switzerland or the United
          Kingdom, transfers of EU Personal Data from the Customer’s Slack
          workspace to Plainice in the US (or in another country not deemed by
          the European Commission to have adequate data protection) are governed
          by the Standard Contractual Clauses for the transfer of EU Personal
          Data to processors established in third countries in the form set out
          by European Commission Decision 2010/87/EU (“Standard Contractual
          Clauses”), the terms of which are hereby incorporated into this
          Addendum.
        </p>
        <h3>6.1</h3>
        <p>In furtherance of the forgoing, the parties agree that:</p>
        <ol>
          <li>
            for purposes of the Standard Contractual Clauses, (a) Customer will
            act as the data exporter; and (b) Plainice will act as the data
            importer;
          </li>
          <li>
            for purposes of Appendix 1 to the Standard Contractual Clauses, the
            categories of data subjects, data, special categories of data (if
            appropriate) and the processing operations shall be as set out in
            Section 1 to this Addendum (Nature of Data Processing);
          </li>
          <li>
            for purposes of Appendix 2 to the Standard Contractual Clauses, the
            technical and organizational measures shall be the Security
            Measures;
          </li>
          <li>
            upon data exporter’s request under the Standard Contractual Clauses,
            data importer will provide the copies of the subprocessor agreements
            that must be sent by the data importer to the data exporter pursuant
            to Clause 5(j) of the Standard Contractual Clauses, and that data
            importer may remove or redact all commercial information or clauses
            unrelated to the Standard Contractual Clauses or their equivalent
            beforehand;
          </li>
          <li>
            the audits described in Clause 5(f) and Clause 12(2) of the Standard
            Contractual Clauses shall be performed in accordance with Section 5
            of this Addendum (Audit; Certification) and satisfy the parties’
            rights and obligations under the Standard Contractual Clauses;
          </li>
          <li>
            Customer agrees that the provisions of Section 4.3 of this Addendum
            satisfy the requirements under the Standard Contractual Clauses
            between Customer and Plainice under Clause 5(d)(ii);
          </li>
          <li>
            Customer’s authorizations in Section 4.1(F) of this Addendum
            constitute Customer’s prior written consent to the subcontracting by
            Plainice of the processing of EU Personal Data if such consent is
            required under Clauses 5(h) and 11(1) of the Standard Contractual
            Clauses; and
          </li>
          <li>
            certification of deletion of EU Personal Data in Clause 12(1) of the
            Standard Contractual Clauses shall be provided upon Customer’s
            request.
          </li>
        </ol>
        <h3>6.2</h3>
        <p>
          Notwithstanding the foregoing, the Standard Contractual Clauses (or
          obligations the same as those under the Standard Contractual Clauses)
          will not apply to the extent an alternative recognized compliance
          standard for the lawful transfer of EU Personal Data outside the EEA
          (e.g., binding corporate rules) applies to the transfer.
        </p>
        <h1>7. Analytics</h1>
        <p>
          Customer acknowledges and agrees that Plainice may create and derive
          from processing under the Agreement anonymized and/or aggregated data
          that does not identify Customer or any natural person, and use,
          publicize or share with third parties such data to improve Plainice’s
          products and services and for its other lawful business purposes.
        </p>
        <h1>8. Term</h1>
        <p>
          This Addendum shall remain in effect as long as Plainice carries out
          Personal Data processing operations on behalf of Customer or until the
          termination of the Agreement and all associated order forms (and all
          Personal Data has been returned or deleted in accordance with section
          9 below).
        </p>
        <h1>9. Data Return and Deletion</h1>
        <p>
          The parties agree that upon the expiration or termination of the
          Agreement, Plainice shall securely destroy all Personal Data and, at
          the request of Customer, certify that it has taken such measures,
          unless applicable laws prevent Plainice from returning or destroying
          all or part of the Personal Data disclosed. In such case, Plainice
          agrees to preserve the confidentiality of the Personal Data retained
          by it and that it will only actively process such Personal Data after
          such date in order to comply with the laws it is subject to.
        </p>
        <h1>10. Liability</h1>
        <p>
          The total combined liability of either party towards the other party,
          whether in contract, tort or any other theory of liability, under or
          in connection with this Addendum and the Standard Contractual Clauses
          (if entered into as described in Section 6 of this Addendum) combined
          will be limited to the liability limitations or other liability caps
          agreed to by the parties in the Agreement.
        </p>
        <p>
          Notwithstanding the foregoing, nothing in this Section 10 will affect
          any party’s liability to data subjects under the third-party
          beneficiary provisions of the Standard Contractual Clauses to the
          extent the limitation of such rights is prohibited by Privacy Laws or
          Local Data Protection Laws, where applicable.
        </p>
        <br />
        <br />
        <br />
        <h1>Attachment 1</h1>
        <p>Security Measures</p>
        <p>
          Plainice may update the Security Measures from time to time, provided
          the updated measures do not decrease the overall protection of
          Personal Data.
        </p>
        <ol>
          <li>
            Organizational management and staff responsible for the development,
            implementation and maintenance of the Plainice’s information
            security program.
          </li>
          <li>
            Audit and risk assessment procedures for the purposes of periodic
            review and assessment of risks to Plainice’s organization,
            monitoring and maintaining compliance with the Plainice’s policies
            and procedures, and reporting the condition of its information
            security and compliance to internal senior management.
          </li>
          <li>
            Data security controls which include, at a minimum, logical
            segregation of data, restricted (e.g. role-based) access and
            monitoring, and utilization of commercially available industry
            standard encryption technologies for Personal Data that is
            transmitted over public networks (i.e. the Internet) or when
            transmitted wirelessly or at rest or stored on portable or removable
            media (i.e. laptop computers, CD/DVD, USB drives, back-up tapes).
          </li>
          <li>
            Logical access controls designed to manage electronic access to data
            and system functionality based on authority levels and job
            functions, (e.g. granting access on a need-to-know and least
            privilege basis, use of unique IDs and passwords for all users,
            periodic review and revoking/changing access promptly when
            employment terminates or changes in job functions occur).
          </li>
          <li>
            Password controls designed to manage and control password strength,
            expiration and usage including prohibiting users from sharing
            passwords and requiring that the Plainice’s passwords that are
            assigned to its employees have defined complexity.
          </li>
          <li>
            System audit or event logging and related monitoring procedures to
            proactively record user access and system activity.
          </li>
          <li>
            Change management procedures and tracking mechanisms designed to
            test, approve and monitor all material changes to the Plainice’s
            technology and information assets.
          </li>
          <li>
            Incident management procedures designed to allow Plainice to
            investigate, respond to, and mitigate events related to the
            Plainice’s technology and information assets.
          </li>
          <li>
            Disaster recovery procedures designed to maintain service and/or
            recover from foreseeable emergencies or disasters.
          </li>
        </ol>
      </Container>
    </LegalLayout>
  );
}
